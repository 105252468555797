import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image'

const Banner = (props) => {

    return(
        <section className={`banner ${props.bannerAlt}`}>  
            <div className="banner__img">
                <Image fluid={props.image} />
            </div>
            <div className="banner__container">
                <div className="banner__copy">
                    <h1 className="banner__title">
                        { props.heading }
                    </h1>
                </div>  
                <div className="banner__controls">
                    <Link to="/products/" className="cta cta--black">
                        See Our Wines
                    </Link>
                </div> 
            </div>     
        </section>
    )
};

export default Banner;
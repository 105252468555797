import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
// import AddToCart  from "../Cart/AddToCart"

const ProductsItem = ({ product }) => {
  //console.log(product)

  return (
    <Link to={`/products/${product.slug.current}/`} className="product" key={product.id} >
        <div className="product__thumb">
            <Image 
              fixed={product?.mainImage?.asset?.fixed} 
            />
        </div>
        
        <div className="products__copy">
            <h2 className="product__title">{product.title}</h2>
            <p className="product__price">${product.defaultPrice}</p>
        </div>

        <div className="product__learnmore">
          <span className="cta">Learn More</span>
        </div>
    </Link>
  )
}

export default ProductsItem

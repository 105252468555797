import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Banner from '../components/Banner/Banner'
import ProductsItem from '../components/ProductsListingItem/ProductsListingItem'

export const productPageMainQuery = graphql`
  query ProductsPageMainQuery {
    sanityPage(slug: {current: {eq: "products"}}) {
      pageBanner {
        title
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    products: allSanityProduct(sort: {fields: title, order: DESC}) {
        edges {
          node {
            id
            title
            defaultPrice
            productOrigin
            slug {
                current
            }
            mainImage {
                asset {
                    fixed(height: 313, width: 250) {
                        ...GatsbySanityImageFixed
                    }
                }
            }
          }
        }
      }
  }
`

const ProductsPage = ({data}) => {
    return (
        <Layout>        
            <div className="container products">
                <StaticQuery
                    query={productPageMainQuery}
                    render={({ products }) => {
                    return (
                        <div className="product__grid">
                            {products.edges.map(({ node: product }) => (
                                <ProductsItem key={product.id} product={product} />
                            ))}
                        </div>
                    )
                    }}
                />
            </div>

        </Layout>
    )
}

export default ProductsPage;
